import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_PSY_API_URL = process.env.REACT_APP_PSY_URL;

const baseAxios = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

baseAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      try {
        localStorage.removeItem('token');
        window.location.href = '/login';
      } catch (error) {
        console.log('Error', error);
      } finally {
        window.location.href = '/';
      }
    }
    return Promise.reject(error);
  }
);

export const psyfiersAxios = axios.create({
  baseURL: BASE_PSY_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

psyfiersAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    console.log('ERRROROR', error);
    if (error.response?.status === 401) {
      try {
        localStorage.removeItem('token');
        window.location.href = '/login';
      } catch (error) {
        console.log('Error', error);
      } finally {
        window.location.href = '/';
      }
    }
    // return new Error(error);
    return Promise.reject(error);
  }
);

export const setAuthToken = (token) => {
  psyfiersAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const setOrgHeaders = (orgPrefix, team) => {
  psyfiersAxios.defaults.headers.common['orgPrefix'] = orgPrefix;
  psyfiersAxios.defaults.headers.common['team'] = team;
};

export const getPortalDetails = (prefix, type) => {
  return baseAxios.get(
    `/public/get-portal-details/${prefix?.toLowerCase()}/${type?.toLowerCase()}`
  );
};

export const fetchTranslations = () => {
  return baseAxios.get(`/public/translations/student-portal`);
};

export const generateStudentPDFURL = (body) => {
  return baseAxios.post('/public/generate-student-pdf', body);
};

export const addNewStudent = (body) => {
  return baseAxios.post(`/student/profile`, body);
};

export const getClassImages = (params) => {
  return baseAxios.get(`/student/class-images`, { params });
};

export const sendStudentPdfInEmail = (body) => {
  return baseAxios.post(`/student/send-profile-pdf-email`, body);
};

export const loginStudent = (body) => {
  return psyfiersAxios.post('/auth/student', body);
};

export const getStudentProfile = (body) => {
  return psyfiersAxios.get('/student', body);
};

export const storeStats = (body) => {
  return baseAxios.post('/public/stats', body);
};
